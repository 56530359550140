import { Button, Grid, Link, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { TrollyDetails } from '../model/makeData';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';

export const ReceiveTrolly = () => {
  const { httpPost, handleAlertBar } = useAPI();
  const [tableData, setTable] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const columns = useMemo<MRT_ColumnDef<TrollyDetails>[]>(
    () => [
      {
        accessorKey: 'trollyId',
        header: 'TrollyId',
        size: 10,
      },
      {
        accessorKey: 'type',
        header: 'Trolly Type',
        size: 10,
      },
      {
        accessorKey: 'trollyStatus',
        header: 'Trolly Status',
        size: 10,
      },
    ],
    [],
  );

  const receiveTrolly = () => {
    const barCode = document.getElementById('trollyBarCode') as HTMLInputElement;
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.TROLLY_RECEIVE_POST}/${barCode.value}`,
      null,
    ).then((response) => {
      if (response.status === 200) {
        try {
          handleAlertBar('success', 'Trolly received');
          fetchAllTrolly();
          barCode.value = '';
        } catch (error: any) {
          throw new Error('Error fetching Print label: ', error);
        }
      } else {
        if (response.data.error) {
          console.error('Error fetching Print label: ', response.data.message);
        }
      }
    });
  };

  const fetchAllTrolly = () => {
    setIsLoading(true);
    httpPost(`${WASHUB_CONSTANTS.API_URLS.TROLLY_DATA_POST}`, null).then(
      (response) => {
        if (response.status === 200) {
          try {
            setIsLoading(false);
            setTable(response.data);
          } catch (error: any) {
            setIsLoading(false);
            throw new Error('Error fetching Print label: ', error);
          }
        } else {
          if (response.data.error) {
            setIsLoading(false);
            console.error(
              'Error fetching Print label: ',
              response.data.message,
            );
          }
        }
      },
    );
  };

  useEffect(() => {
    fetchAllTrolly();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Receive Trolly
          </Typography>
        </Grid>
      </Grid>
      <Grid container display={'flex'} flexDirection={'row'} className="p-3">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <input
            type="text"
            id='trollyBarCode'
            placeholder="Scan the Trolly Label..."
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Button
            variant="contained"
            className="me-2"
            onClick={() => {
              receiveTrolly();
            }}
          >
            Receive Trolly
          </Button>
        </Grid>
      </Grid>
      <div style={{ margin: '5px 15px', borderTop: '2px solid #25BCCA' }}></div>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          defaultColumn={{
            maxSize: 150,
            minSize: 10,
            size: 50,
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTablePaperProps={{
            sx: {
              width: '100%',
              '& .css-1tbggly': {
                minHeight: '2px',
              },
            },
          }}
          columns={columns}
          enableFullScreenToggle={false}
          enableStickyHeader
          positionActionsColumn="last"
          enableRowActions
          enableColumnActions={false}
          enableColumnFilters={false}
          enableFilters={true}
          enableSorting={false}
          enableHiding={false}
          enableDensityToggle={false}
          enableToolbarInternalActions={false}
          data={tableData}
          state={{ isLoading: isLoading }}
          editDisplayMode="modal"
          muiTableContainerProps={{
            sx: {
              height: '63vh',
            },
          }}
        />
      </div>
    </div>
  );
};

