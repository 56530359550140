import { Button, debounce, Grid, Link, Paper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { TrollyPackageOrderDetails } from '../model/makeData';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { QrCode } from '@mui/icons-material';
declare const BrowserPrint: any;
export const TrollyManagement = () => {
  const { httpPost, handleAlertBar } = useAPI();
  const [barcode, setBarCode] = useState('');
  const [qrCodes, setQrCodes] = useState(['']);
  const [tables, setTables] = useState<any>([]);
  const [trollyData, setTrollyData] = useState<any>({});
  const columns = useMemo<MRT_ColumnDef<TrollyPackageOrderDetails>[]>(
    () => [
      {
        accessorKey: 'serviceItem.name',
        header: 'Item',
        size: 10,
      },
      {
        accessorKey: 'balanceItemCount',
        header: 'Balance Count',
        size: 10,
      },
      {
        accessorKey: 'packageCount',
        header: 'Package Count',
        size: 10,
      },
      {
        accessorKey: 'cleanCount',
        header: 'Clean',
        size: 50,
      },
      {
        accessorKey: 'stainCount',
        header: 'Stain',
        size: 10,
      },
      {
        accessorKey: 'damageCount',
        header: 'Damage',
        size: 10,
      },
    ],
    [],
  );

  const fetchTrollyPackage = (qrCode: string) => {
    const reqBody = {
      trollyId: barcode,
      packageQRCode: qrCode,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.TROLLY_PACKAGE_ORDER_CREATE_POST}`,
      reqBody,
    ).then((response) => {
      if (response.status === 200) {
        setTrollyData(response.data);
        setTables(response?.data?.trollyPackageOrder);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const callApiDebounce = useCallback(debounce(fetchTrollyPackage, 1000),[barcode]);

  const removeQrCode = (index: number, qrCode: string) => {
    const reqBody = {
      trollyId: barcode,
      packageQRCode: qrCode,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.TROLLY_PACKAGE_DELETE_POST}`,
      reqBody,
    ).then((response) => {
      if (response.status === 200) {
        setQrCodes(qrCodes.filter((_, i) => i !== index));
        setTrollyData(response.data);
        setTables(response?.data?.trollyPackageOrder);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const handleQrScan = (index: number, value: string) => {
    const updatedQrCodes = [...qrCodes];
    updatedQrCodes[index] = value;
    if(value) {
      callApiDebounce(value);
    }
    if (index === qrCodes.length - 1 && value) {
      updatedQrCodes.push('');
    }
    setQrCodes(updatedQrCodes);
  };

  const handleBarCode = (value: string) => {
    setBarCode(value);
  };

  const fetchPrintBatch = () => {
    const reqBody = {
      trollyId: barcode,
      packageOrderId: trollyData.trollyPackageOrderId,
      orderId: trollyData?.trollyPackageOrder.map((item: any) => {
        return item.orderId;
      })[0],
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.TROLLY_PRINT_DISPATCH_POST}`,
      reqBody,
    ).then((response) => {
      if (response.status === 200) {
        try {
          BrowserPrint.getLocalDevices(function(deviceList: any) {
            const device = deviceList?.printer;
            if(device?.length == 0 || device === undefined) {
              handleAlertBar('error','Printer not found');
              return;
            }
            let zebraPrinter: any = null;
            for(let i = 0; i < device?.length; i++) {
              if(device[i]?.manufacturer.toLowerCase().includes('zebra') || device[i]?.name.toLowerCase().includes('zpl')) {
                zebraPrinter = device[i];
                break;
              }
            }
            if(zebraPrinter === null) {
              handleAlertBar('error','No Zebra printer found');
              return;
            }
            response?.data?.label?.map((zpl: string) => {
              zebraPrinter.send(
                zpl,
                (response: any) => {
                  handleAlertBar('success','Labels print successfully');
                  setBarCode('');
                  setQrCodes(['']);
                  setTables([]);
                },
                (error: any) => {
                  console.error('Error in printing: ', error);
                },
              );
            });
          })
        } catch (error: any) {
          throw new Error('Error fetching Print label: ', error);
        }
      } else {
        if (response.data.error) {
          console.error('Error fetching Print label: ', response.data.message);
        }
      }
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Trolly Management
          </Typography>
        </Grid>
      </Grid>
      {tables.length > 0 &&  (
        <div
          style={{ padding: '5px 15px', maxHeight: '45vh', overflow: 'auto' }}
        >
          {tables?.map((order: any, idx: number) => (
            <Paper key={idx} className="pt-2 px-2 mb-2">
              <Typography>{`Order Id: ${order.orderId}`}</Typography>
              <MaterialReactTable
                defaultColumn={{
                  maxSize: 150,
                  minSize: 10,
                  size: 50,
                }}
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    width: '100%',
                    '& .css-1tbggly': {
                      minHeight: '0px',
                    },
                    '& .css-10gei56': {
                      minHeight: '0px',
                    },'&. css-1llu0od':{
                      minHeight: '0px',
                    }
                  },
                }}
                columns={columns}
                enableFullScreenToggle={false}
                enableStickyHeader
                positionActionsColumn="last"
                enableRowActions={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableHiding={false}
                enableDensityToggle={false}
                enableToolbarInternalActions={false}
                data={order.trollyPackageOrderItem}
                editDisplayMode="modal"
                muiTableContainerProps={{
                  sx: {
                    height: 'auto',
                  },
                }}
              />
            </Paper>
          ))}
        </div>
      )}

      <div style={{ margin: '5px 15px', borderTop: '2px solid #25BCCA' }}></div>
      <Grid
        container
        display={'flex'}
        flexDirection={'row'}
        className="p-4"
        sx={{ maxHeight: '30vh', overflow: 'auto' }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <input
            type="text"
            id='trollyBarCode'
            value={barcode}
            placeholder="Scan the Trolly..."
            onInput={(e) => handleBarCode((e.target as HTMLInputElement).value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {qrCodes?.map((qr: string, index: number) => (
            <Grid
              container
              key={index}
              display={'flex'}
              flexDirection={'row'}
              className="mb-4"
            >
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <input
                  type="text"
                  style={{ width: '100%' }}
                  data-index={index}
                  value={qr}
                  placeholder="Scan the Package label..."
                  onInput={(e) => handleQrScan(index, (e.target as HTMLInputElement).value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                {qr && (
                  <Link component="button" title={'Remove'}>
                    <CloseIcon
                      className="ms-4 mb-1"
                      color="error"
                      fontSize="small"
                      onClick={() => removeQrCode(index, qr)}
                    />
                  </Link>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div className="d-flex justify-content-end me-2">
        <Button
          variant="contained"
          className="me-2"
          onClick={() => {
            fetchPrintBatch();
          }}
        >
          Print Batch Label
        </Button>
      </div>
    </div>
  );
};
