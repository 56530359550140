import { Box, Button, Grid, Link, Typography, Paper, debounce } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useAPI } from '../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { TrollyPackageOrderDetails } from '../model/makeData';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';

export const DeliveryTrolly = () => {
  const { httpPost, axiosInstance, handleAlertBar } = useAPI();
  const [qrCodes, setQrCodes] = useState(['']);
  const [tables, setTables] = useState<any>([]);
  const [trollyData, setTrollyData] = useState<any>({});
  const columns = useMemo<MRT_ColumnDef<TrollyPackageOrderDetails>[]>(
    () => [
      {
        accessorKey: 'serviceItem.name',
        header: 'Item',
        size: 10,
      },
      {
        accessorKey: 'balanceItemCount',
        header: 'Balance Count',
        size: 10,
      },
      {
        accessorKey: 'packageCount',
        header: 'Package Count',
        size: 10,
      },
      {
        accessorKey: 'cleanCount',
        header: 'Clean',
        size: 50,
      },
      {
        accessorKey: 'stainCount',
        header: 'Stain',
        size: 10,
      },
      {
        accessorKey: 'damageCount',
        header: 'Damage',
        size: 10,
      },
    ],
    [],
  );

  // POST with API : Generate delivery Notes
  const fetchGenerateDeliveryNotesPdf = async (row: any) => {
    const payload = {
      trollyDeliveryOrderId: trollyData.trollyDeliveryOrderId,
      orderId: row.orderId,
    };
    axiosInstance
      .post(
        WASHUB_CONSTANTS.API_URLS.TROLLY_DELIVERY_PRINT_DISPATCH_POST,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem('jwtToken') || null,
          },
          responseType: 'blob',
        },
      )
      .then((response: any) => {
        if (response?.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          const contentDisposition = response.headers['content-disposition'];
          let fileName = `trolly-delivery-note-${payload?.trollyDeliveryOrderId}`;
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }
          console.log(contentDisposition);
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } else {
          if (response?.data?.error) {
            console.error(
              'Error fetching BusinessStoreInvoices: ',
              response?.data?.message,
            );
          }
        }
      })
      .catch((err) => {
        console.log('Second catch');
      });
  };

  // POST with API : Generate delivery Notes
  const fetchEmailDeliveryNotesPdf = async (row: any) => {
    const payload: any = {
      trollyDeliveryOrderId: trollyData.trollyDeliveryOrderId,
      orderId: row.orderId,
    };
    axiosInstance
      .post(
        WASHUB_CONSTANTS.API_URLS.TROLLY_DELIVERY_PRINT_DISPATCH_EMAIL_POST,
        payload,
        {
          headers: {
            Authorization: localStorage.getItem('jwtToken') || null,
          },
          responseType: 'blob',
        },
      )
      .then((response: any) => {
        if (response?.status === 200) {
          handleAlertBar(
            'success',
            'Delivery note has been sent to your email',
          );
        } else {
          if (response?.data?.error) {
            console.error(
              'Error fetching BusinessStoreInvoices: ',
              response?.data?.message,
            );
          }
        }
      })
      .catch((err) => {
        console.log('Second catch');
      });
  };

  const fetchTrollyDeliveryPackage = async (qrCode: string) => {
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.TROLLY_DELIVERY_ORDER_CREATE_POST}`,
      qrCode,
    ).then((response) => {
      if (response.status === 200) {
        setTrollyData(response.data);
        setTables(response?.data?.trollyPackageOrder);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const callApiDebounce = useCallback(debounce(fetchTrollyDeliveryPackage, 1000),[]);

  const removeQrCode = (index: number, qrCode: string) => {
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.TROLLY_DELIVERY_DELETE_POST}`,
      qrCode,
    ).then((response) => {
      if (response.status === 200) {
        setQrCodes(qrCodes.filter((_, i) => i !== index));
        setTrollyData(response.data);
        setTables(response?.data?.trollyPackageOrder);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  const handleQrScan = (index: number, value: string) => {
    const updatedQrCodes = [...qrCodes];
    updatedQrCodes[index] = value;

    if (index == qrCodes.length - 1 && value) {
      updatedQrCodes.push('');
    }
    if(value) {
      callApiDebounce(value);
    }
    setQrCodes(updatedQrCodes);
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Trolly Delivery Management
          </Typography>
        </Grid>
      </Grid>
      <div style={{ padding: '5px 15px', maxHeight: '50vh', overflow: 'auto' }}>
        {tables.length > 0 &&
          tables.map((data: any, index: number) => (
            <Paper key={index} className="p-2 mb-2">
              <Typography>{`Order Id: ${data.orderId}`}</Typography>
              <MaterialReactTable
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    width: '100%',
                    '& .css-1tbggly': {
                      minHeight: '0px',
                    },
                    '& .css-10gei56': {
                      minHeight: '0px',
                    },
                  },
                }}
                muiTableContainerProps={{
                  sx: {
                    height: 'auto',
                  },
                }}
                defaultColumn={{
                  maxSize: 150,
                  minSize: 10,
                  size: 50,
                }}
                columns={columns}
                enableFullScreenToggle={false}
                enableStickyHeader
                positionActionsColumn="last"
                enableRowActions={false}
                enableColumnActions={false}
                enableColumnFilters={false}
                enableFilters={false}
                enablePagination={false}
                enableSorting={false}
                enableHiding={false}
                enableDensityToggle={false}
                enableToolbarInternalActions={false}
                data={data.trollyPackageOrderItem}
                editDisplayMode="modal"
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
                >
                  <Button
                    sx={{ color: 'white' }}
                    variant="contained"
                    onClick={() => fetchGenerateDeliveryNotesPdf(data)}
                  >
                    {'Print Delivery Note'}
                  </Button>
                  <Button
                    sx={{ color: 'white' }}
                    variant="contained"
                    onClick={() => fetchEmailDeliveryNotesPdf(data)}
                  >
                    {'Email Delivery Note'}
                  </Button>
                </Box>
              </div>
            </Paper>
          ))}
      </div>

      <div style={{ margin: '5px 15px', borderTop: '2px solid #25BCCA' }}></div>
      <Grid
        container
        display={'flex'}
        flexDirection={'row'}
        className="p-4"
        sx={{ maxHeight: '30vh', overflow: 'auto' }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {qrCodes?.map((qr: string, index: number) => (
            <Grid
              container
              key={index}
              display={'flex'}
              flexDirection={'row'}
              className="mb-4"
            >
              <Grid item xs={6} sm={6} md={10} lg={10}>
                <input
                  type="text"
                  style={{ width: '50%' }}
                  data-index={index}
                  value={qr}
                  placeholder="Scan the Batch label..."
                  onChange={(e) => handleQrScan(index, e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={2} lg={2}>
                {qr && (
                  <Link component="button" title={'Remove'}>
                    <CloseIcon
                      className="ms-4 mb-1"
                      color="error"
                      fontSize="small"
                      onClick={() => removeQrCode(index, qr)}
                    />
                  </Link>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
