import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../shared/services/api/API';
import { WASHUB_CONSTANTS } from '../../../../shared/Constants';
import { IOrderItem } from '../../../../shared/models/Order.model';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from '@mui/icons-material/Add';
import {
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MaterialReactTable,
  MaterialReactTableProps,
} from 'material-react-table';
import { BusinessContext } from '../../../../shared/BusinessContext';
import { SharedApi } from '../../../../shared/services/shared-api/SharedApi';
import { AlertDialog } from '../../../../shared/components/alert-dialog/AlertDialog';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const BusinessOrderDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {getItemStatusOptions} = SharedApi();
  const orderId = location?.state?.order?.orderId || location?.state?.orderId;
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [tableData, setTableData] = useState<IOrderItem[]>([]);
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const [ statusOptions, setStatusOptions] = useState<any>(['READYTODELIVER_PARTIAL','READYTODELIVER_FORCECLOSE']);
  const [ categoryList, setCategoryList] = useState([]);
  const [ category, setCategory] = useState('');
  const [ itemList, setItemList] = useState([]);
  const [ filteredItemList, setFilteredItemList] = useState<any>([]);
  const [ serviceItemList, setServiceItemList] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [serviceItemObj, setServiceItemObj] = useState({
    serviceItem: {},
    itemCount: ''
  });
  const [validationErrors, setValidationErrors] = useState<any>({})
  const validateRequired = (value: string) => !!value.length;
  const [editedOrderItems, setEditedOrderItems] = useState<Record<string, IOrderItem>>({});
  const [isUpdatingOrderItems, setIsUpdatingOrderItems] = useState(false);
  const [isAlertDialog, setIsAlertDialog] = useState(false);
  const [deleteRowData, setDeletedRowData] = useState<any>([]);
  const [countFormData, setCountFormData] = useState({
    processItemCount: '',
    stainCount: '',
    damageCount: ''
  })
  const [openCountDialog, setOpenCountDialog] = useState(false);
  const [selectedRow, setSelectedRows] = useState<any>(null);
  const [error, setError] = useState("");
  const [serviceTypeList, setServiceTypeList] = useState([]);
  const [serviceTypeId, setServiceTypeId] = useState('');
  const todayDate = new Date();
  const end = new Date();
  const tomorrowDate = end.setDate(end.getDate() + 1);
  const key = localStorage.getItem('key');
  const [pickupDate, setPickupDate] = useState(dayjs(todayDate));
  const [deliveryDate, setDeliveryDate] = useState(dayjs(tomorrowDate));
  const handlePickupDateChange = (date: any) => {
    setPickupDate(date);
  };
  const handleDeliveyDateChange = (date: any) => {
    setDeliveryDate(date);
  };
  const [billNo, setBillNo] = useState('');
  const [orderNote, setOrderNote] = useState('');

  const handleServiceTypeData = (id: string) => {
    setServiceTypeId(id);
  };

  const handleChange =(event: any, row: any) => {
    const {name, value} = event.target;
    setEditedOrderItems((prevEditedOrderItems) => ({
      ...prevEditedOrderItems, [row.id]: {
        ...prevEditedOrderItems[row.id], id: row.id, [name]: name === "processItemCount" ? +value : value
      }}
    ))
  }

  const handleSave = () => {
    if (countFormData.stainCount + countFormData.damageCount > countFormData.processItemCount) {
      setError("Stain Count + Damage Count cannot be greater than Process count");
      return;
    }
    const row = selectedRow;
    tableData.map((item) => { if(item.id === selectedRow.id ) { 
        item.processItemCount = +countFormData.processItemCount,
        item['stainCount'] = +countFormData.stainCount,
        item['damageCount'] = +countFormData.damageCount
      } 
      return item
    })
    setEditedOrderItems((prevEditedOrderItems) => ({
      ...prevEditedOrderItems, [row.id]: {
        ...prevEditedOrderItems[row.id], id: row.id, processItemCount: +countFormData.processItemCount, damageCount: +countFormData.damageCount, stainCount: +countFormData.stainCount
      }}
    ));
    setOpenCountDialog(false);
  }

  const handleOpenCountDialog = (row: any) => {
    setSelectedRows(row);
    setCountFormData({
      processItemCount: row.processItemCount,
      stainCount: row.stainCount || 0,
      damageCount: row.damageCount || 0
    });
    setError('');
    setOpenCountDialog(true);
  }

  const handleCountChange = (e : any) => {
    const {name,value} = e.target;
    const newFormData = {...countFormData, [name]: +value};
    if (newFormData.stainCount + newFormData.damageCount > newFormData.processItemCount) {
      setError("Stain Count + Damage Count cannot be greater than Process count");
    } else {
      setError('');
    }
    setCountFormData(newFormData);
  }

  const handleCloseCountDialog = () => {
    setOpenCountDialog(false);
  }

  const columns = useMemo<MRT_ColumnDef<IOrderItem>[]>(
    () => [
      {
        accessorKey: 'serviceItem.name',
        header: 'Item Name',
        enableEditing: false,
        size: 40,
      },
      {
        accessorKey: 'serviceItem.serviceCategoryName',
        header: 'Item Category',
        enableColumnOrdering: false,
        enableEditing: false,
        size: 50,
      },
      {
        accessorKey: 'itemCount',
        header: 'Total Count',
        enableEditing: false,
        enableHiding: true,
        size: 40,
      },
      {
        accessorKey: 'deliverItemCount',
        header: 'Delivery Count',
        enableEditing: false,
        size: 40,
      },
      {
        accessorKey: 'balanceItemCount',
        header: 'Balance Count',
        enableEditing: false,
        size: 40,
      },
      {
        accessorKey: 'processItemCount',
        header: 'Process Count',
        size: 40,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          type: 'text',
          required: true,
          error: !!validationErrors?.[cell.id],
          helperText: validationErrors?.[cell.id],
          //store edited row in state to be saved later
          onBlur: (event) => {
            const validationError = !validateRequired(event.currentTarget.value)
              ? 'Required'
              : undefined;
            setValidationErrors({
              ...validationErrors,
              [cell.id]: validationError,
            });
            if(row.original?.balanceItemCount > +event.target.value) {
              if(row.original.orderItemStatus) {
                setStatusOptions([row.original?.orderItemStatus,'READYTODELIVER_PARTIAL','READYTODELIVER_FORCECLOSE',])
              }
            }
          }
        }),
        Cell: ({cell}) => (
          <Button fullWidth onClick={() => handleOpenCountDialog(cell.row.original)}>{cell.row.original.processItemCount}</Button>
        )
      },
      {
        accessorKey: 'orderItemStatus',
        header: 'Status',
        size: 50,
        editVariant: 'select',
        editSelectOptions: statusOptions,
        muiEditTextFieldProps: ({ cell, row, table }) => ({
          select: true,
          onChange: (event: any) => handleChange(event,row.original),
        }),
        Cell: ({cell}) => (
          <Typography sx={{
            backgroundColor: cell.getValue<string>() === 'CANCELLED'
              ? 'red'
              : cell.getValue<string>() === 'DELIVERED'
              ? '#83d37b'
              : cell.getValue<string>() === 'DELIVERED_PARTIAL'
              ? 'teal'
              : cell.getValue<string>() === 'READYTODELIVER'
              ? '#FFE5B4'
              : cell.getValue<string>() === 'READYTODELIVER_PARTIAL'
              ? 'yellow'
              : cell.getValue<string>() === 'CREATED'
              ? '#D3D3D3'
              : 'none',
              p: 1
          }} variant='labelNormal' title={cell.getValue<string>()} > {cell.getValue<string>()}</Typography>
      ),
      },
    ],
    [editedOrderItems, validationErrors, statusOptions],
  );

    const [valuesDetail, setValuesDetail] = useState<any>(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {} as any),
  );

  //Get Order Detail By Order Id
  const fetchOrders = async (orderId: string) => {
    httpGet(
      WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_DETAILS_DATA_GET +
        '/' +
        orderId,
    )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.orderItems?.length) {
            setOrderDetails(response?.data);
            setBillNo(response?.data?.billNo);
            setOrderNote(response?.data?.orderNote);
            setServiceTypeId(response?.data?.serviceType?.id)
            setPickupDate(dayjs(response.data.pickupDate));
            setDeliveryDate(dayjs(response.data.deliveryDate));
            setTableData(response?.data?.orderItems);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
      fetchOrders(orderId);
      fetchBusinessStoreServiceTypeData();
  }, []);

  const backToOrderList = () => {
    navigate('..', { state: { orderDetails } });
  };

  const addItem = () => {
    setOpenDialog(true);
    fetchServiceItem();
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

      //Get Service Item
      const fetchServiceItem = async () => {
        httpGet(
          `${WASHUB_CONSTANTS.API_URLS.BUSINESS_SERVICEITEM_V2_ADMINSERVICEITEM__DATA_FOR_BUSINESSDEPT_GET}/${orderDetails?.businessStore?.id}/${orderDetails?.businessDepartment?.id}`
        )
          .then((response) => {
            if (response.status === 200) {
              if (response?.data) {
                setCategoryList(response?.data?.serviceCategories);
                setServiceItemList(response?.data?.retailServiceItem);
              }
            } else {
              handleAlertBar("error", "Service Unavailable");
            }
          })
          .catch((error) => console.log(error));
      };

  const handleAddItem = async () => {
    const serviceItemOrder = [serviceItemObj.serviceItem];
    const serviceItems = serviceItemOrder?.map((item: any) => {
      return {
        specialInstructions: 'string',
        amount: item.price * +serviceItemObj.itemCount,
        itemCount: +serviceItemObj.itemCount,
        categoryId: item.category.id,
        serviceItemId: item.id,
      };
    });
    const requestBody = {
      businessId: orderDetails?.businessStore?.business?.id,
      businessStoreId: orderDetails?.businessStore?.id,
      serviceItemOrder: serviceItems,
      orderNote: orderDetails?.orderNote,
      departmentId: orderDetails?.businessDepartment?.id
    };
    await httpPost(`${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_ADD_ITEM_POST}/${orderDetails.orderId}`, requestBody).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message
            );
          } else {
            handleCloseDialog();
            handleAlertBar("success", "Add item sucessfully");
            setCategory('');
            setServiceItemObj({
              serviceItem: {},
              itemCount: ''
            })
            fetchOrders(orderDetails.orderId);
          }
        } else {
          handleAlertBar("error", "Service Unavailable");
        }
      },
      (error) => {console.log(error)}
    );
  };

  const handleFilterService = async (category: any) => {
    const list: any = serviceItemList?.find(
      (item: any) => category === item.categoryName,
    );
    await setItemList(list?.optiServiceItem);
  };

  const handleSaveRows = async () => {
    if (Object.values(validationErrors).some((error) => !!error)) return;
    await updateOrderItems(Object.values(editedOrderItems));
    setEditedOrderItems({});
  };

  const updateOrderItems = async (data: any) => {

    setIsUpdatingOrderItems(true);
    const existServiceItems = data?.map((item: any) => {
      return {
        orderItemId: item?.id,
        processCount: item?.processItemCount || 0,
        status: item?.orderItemStatus,
        stainCount: item?.stainCount || 0,
        damageCount: item?.damageCount || 0
      };
    });
    const payload = {
      orderId: orderDetails.orderId,
      status: orderDetails.orderStatus,
      orderItem: existServiceItems
    }
    await httpPost(`${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_ITEM_UPDATE_POST}`, payload).then(
      (response) => {
        if (response && response.data) {
          setIsUpdatingOrderItems(false);
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message
            );
          } else {
            handleCloseDialog();
            handleAlertBar("success", "Order Items updated sucessfully");
            setCategory('');
            setServiceItemObj({
              serviceItem: {},
              itemCount: ''
            })
            fetchOrders(orderDetails.orderId);
          }
        } else {
          handleAlertBar("error", "Service Unavailable");
        }
      },
      (error) => {console.log(error)}
    );
  };

  const deleteOrderItems = async () => {
    const payload = {
      orderId: orderDetails.orderId,
      orderItem: [
        {
          orderItemId: deleteRowData?.id
        }
      ]
    }
    await httpPost(`${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_ITEM_DELETE_POST}`, payload).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message
            );
          } else {
            handleAlertDialogClose();
            handleAlertBar("success", "Order Items deleted sucessfully");
            fetchOrders(orderDetails.orderId);
          }
        } else {
          handleAlertBar("error", "Service Unavailable");
        }
      },
      (error) => {console.log(error)}
    );
  };

  const handleDeleteRow = (row: any) => {
    setDeletedRowData(row);
    setIsAlertDialog(true);
  };
  const handleAlertDialogClose = () => {
    setIsAlertDialog(false);
  };

  const handleUpdateDetails = async() => {
    const reqBody = {
      businessId: orderDetails?.businessStore?.business?.id,
      businessStoreId: orderDetails?.businessStore?.id,
      departmentId: orderDetails?.businessDepartment?.id,
      billNo: billNo,
      pickupDate: pickupDate.format('YYYY-MM-DD'),
      deliveryDate: deliveryDate.format('YYYY-MM-DD'),
      orderNote: orderNote,
      serviceTypeId: serviceTypeId
    }
    await httpPost(`${WASHUB_CONSTANTS.API_URLS.BUSINESSSTOREORDER_META_ITEM_UPDATE_POST}/${orderDetails.orderId}`, reqBody).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar(
              "error",
              response?.data?.message
            );
          } else {
            handleAlertBar("success", "Business Order Meta Data updated sucessfully");
          }
        } else {
          handleAlertBar("error", "Service Unavailable");
        }
      },
      (error) => {console.log(error)}
    );
  }

  const fetchBusinessStoreServiceTypeData = async () => {
    await httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSORDER_SERVICE_TYPE_DATA_GET}`,
    ).then((response) => {
      if (response.status === 200) {
        setServiceTypeList(response?.data);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching Service Type: ',
            response.data.message,
          );
        }
      }
    });
  };

  useEffect(()=>{
    if(itemList?.length > 0) {
      // const filteredOptiServiceItem: any = itemList?.map((section: any)=> ({...section,optiServiceItem : section.optiServiceItem?.filter((item: any) => item.name) }))
      setFilteredItemList(itemList);
    }
  },[itemList])

  return (
    <div>
      <Grid container className='mt-1'>
        <Grid item xs={2} md={0.5}>
          <IconButton onClick={backToOrderList}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10} md={8} lg={8.5}>
          <h4 style={{ marginTop: '5px' }}>
            Order Details: {orderDetails.orderId}
          </h4>
        </Grid>
      </Grid>
      <hr className='m-0'></hr>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <Paper sx={{ width: '100%', height: '100%', padding: '10px' }}>
          <Grid
            container
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item>
              <Typography>
                Order Date: <span>{orderDetails.orderDate}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Total Item: <span>{orderDetails.totalItemCount}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                Status: <span>{orderDetails.orderStatus}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className='mt-2'
            container
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Pickup Date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="pickupDate"
                  value={dayjs(pickupDate)}
                  // minDate={dayjs(todayDate)}
                  onChange={handlePickupDateChange}
                  sx={{width: '15%'}}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={'Delivery date'}
                  slotProps={{ textField: { variant: 'standard' } }}
                  name="deliveryDate"
                  value={dayjs(deliveryDate)}
                  minDate={dayjs(pickupDate)}
                  onChange={handleDeliveyDateChange}
                  className='ms-4'
                  sx={{width: '15%'}}
                />
              </LocalizationProvider>
              <TextField
                sx={{width: '15%', marginTop: '4px'}}
                variant="standard" 
                className='ms-4'
                key="orderNote"
                label="Order Note"
                name="orderNote"
                type="text"
                size='small'
                value={orderNote}
                onChange={(e) => setOrderNote(e.target.value)}
              />
              <TextField
                sx={{width: '15%', marginTop: '4px'}}
                variant="standard" 
                className='ms-4'
                key="billNo"
                label="Bill No."
                name="billNo"
                type="text"
                size='small'
                value={billNo}
                onChange={(e) => setBillNo(e.target.value)}
              />
              <TextField
                sx={{width: '15%'}}
                variant="standard" 
                className='ms-4'
                key="serviceType"
                label="Select Service Type"
                name="serviceType"
                select
                onChange={(e) => handleServiceTypeData(e.target.value)}
                value={serviceTypeId}
                >
                {serviceTypeList.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                ))}
              </TextField>
              <Button
                className='ms-4'
                variant="contained"
                sx={{color: 'white', marginTop: '15px'}}
                onClick={() => handleUpdateDetails()}>
                <Typography variant='labelNormal'>Update Details</Typography>
              </Button>
            </Grid>

          </Grid>
          <div style={{ marginTop: '2px' }}>
            <MaterialReactTable
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  width: '100%',
                  '& .css-1tbggly': {
                    minHeight: '0px',
                  },
                  '& .css-10gei56': {
                    minHeight: '0px',
                  },
                },
              }}
              muiTableContainerProps={{
                sx: {
                  height: '50vh',
                },
              }}
              defaultColumn={{
                maxSize: 150,
                minSize: 10,
                size: 50
              }}
              displayColumnDefOptions={{
                'mrt-row-actions': {
                  muiTableHeadCellProps: {
                    align: 'center',
                  },
                  size: 20,
                },
              }}
              enableEditing={true}
              columns={columns}
              data={tableData}
              enableFullScreenToggle={false}
              enableStickyHeader
              enableColumnFilters={false}
              enableFilters={false}
              layoutMode="grid"
              editDisplayMode='cell'
              enablePagination={false}
              enableSorting={false}
              enableHiding={false}
              enableDensityToggle={false}
              positionActionsColumn="last"
              enableRowActions
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <Button
                    className='ps-1 pe-1'
                    variant="contained"
                    sx={{color: 'white'}}
                    onClick={() => {handleDeleteRow(row?.original)}}>
                      <Typography variant='labelNormal'>Delete</Typography>
                  </Button>
                </Box>
              )}
            />
          </div>
          {orderDetails.orderNote ? (<Box
              sx={{
                display: 'grid',
                margin: 'auto',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
              }}
            >
              <Typography><span className='fw-bold'>Order Note: </span><span>{orderDetails?.orderNote}</span></Typography>
            </Box>) : null}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Link
              component="button"
              title="add order detail"
              onClick={addItem}
            >
              <AddIcon
                fontSize="large"
              />
          </Link>
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                  <Button
                    sx={{color: 'white'}}
                    variant="contained"
                    onClick={handleSaveRows}
                    disabled={
                      Object.keys(editedOrderItems).length === 0 ||
                      Object.values(validationErrors).some((error) => !!error)
                    }
                  >
                    {'Update'}
                  </Button>
                  {Object.values(validationErrors).some((error) => !!error) && (
                    <Typography color="error">Fix errors before submitting</Typography>
                  )}
                </Box>

            </div>
        </Paper>
      </div>
      <Dialog fullWidth
        maxWidth='md'  open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle textAlign="center">Add Item</DialogTitle>
      <DialogContent>
          <TextField
            className='mt-2'
            fullWidth
            key="category"
            label="Select Category"
            name="category"
            select
            onChange={(e) => {
              setCategory(e.target.value);
              handleFilterService(e.target.value);
            } }
            value={category}
          >
            {categoryList?.map((option: any) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className='mt-2'
            fullWidth
            key="item"
            label="Select Item"
            name="item"
            select
            onChange={(e) => 
              setServiceItemObj({
                serviceItem: e.target.value,
                itemCount: serviceItemObj.itemCount
              })
            }
            value={serviceItemObj.serviceItem}
            disabled={category === ''}
          >
            {filteredItemList?.length > 0 && filteredItemList?.map((option: any) => (
              <MenuItem key={option.id} value={option}>
                <img src={option?.mediaItem?.url} width={50} height={40} />
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
          className='mt-2'
          key="itemCount"
          label="Enter Item Count"
          name="itemCount"
          type="text"
          value={serviceItemObj.itemCount}
          required={serviceItemObj.itemCount.length === 0}
          fullWidth
          onChange={(e) =>
            setServiceItemObj({
              serviceItem: serviceItemObj.serviceItem,
              itemCount: e.target.value
            })
          }
        />
      </DialogContent>
      <DialogActions className="dialog-btn">
      <Button color='error' variant="contained" sx={{color: 'white'}} onClick={handleCloseDialog}>Cancel</Button>
        <Button
          onClick={handleAddItem}
          sx={{color: 'white'}}
          variant="contained"
          disabled={category === '' || serviceItemObj.itemCount === '' }
        >
          Add Item
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog fullWidth
        maxWidth='sm'  open={openCountDialog} onClose={handleCloseCountDialog}>
        <DialogTitle textAlign="center">Edit Count</DialogTitle>
        <DialogContent>
          <div className='d-flex item-center justify-content-between'>
          <TextField
            className='mx-2 mt-2'
            key="processItemCount"
            label="Enter Process Count"
            name="processItemCount"
            type="text"
            size='small'
            value={countFormData.processItemCount}
            onChange={(e) => handleCountChange(e)}
          />
          <TextField
            className='mx-2 mt-2'
            key="stainCount"
            label="Enter Stain Count"
            name="stainCount"
            type="text"
            size='small'
            value={countFormData.stainCount}
            onChange={(e) => handleCountChange(e)}
          />
            
          <TextField
            className='mx-2 mt-2'
            key="damageCount"
            label="Enter Damage Count"
            name="damageCount"
            type="text"
            size='small'
            value={countFormData.damageCount}
            onChange={(e) => handleCountChange(e)}
          />

          </div>
          {error && <Typography className='mx-2 mt-2' color="error">{error}</Typography>}
        </DialogContent>
      <DialogActions className="dialog-btn">
      <Button color='error' variant="contained" sx={{color: 'white'}} onClick={handleCloseCountDialog}>Cancel</Button>
        <Button
          onClick={handleSave}
          sx={{color: 'white'}}
          variant="contained"
          disabled={!!error}
        >
          Save
      </Button>
      </DialogActions>
    </Dialog>
    <AlertDialog
      open={isAlertDialog}
      title={'Delete Business Order Item'}
      description={'Are you sure you want to delete this order item ?'}
      submitBtnText={'Delete'}
      cancelBtnText={'Cancel'}
      handleSubmit={deleteOrderItems}
      handleClose={handleAlertDialogClose}
    ></AlertDialog>
    </div>
  );
};
