import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { WASHUB_CONSTANTS } from '../../../shared/Constants';
import { useAPI } from '../../../shared/services/api/API';
import { PackagingLabelOrderDetails } from '../model/makeData';
declare const BrowserPrint: any;

export const PackagingLabel = () => {
  const { httpGet, httpPost, handleAlertBar } = useAPI();
  const [businessStoreId, setBusinessStoreId] = useState('');
  const [businessStoreData, setBusinessStoreData] = useState<any>([]);
  const [departmentId, setDepartmentId] = useState('');
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [serviceItemId, setServiceItemId] = useState('');
  const [serviceItemList, setServiceItemList] = useState<any>([]);
  const [tableData, setTableData] = useState<PackagingLabelOrderDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrintDialogOpen, setIsPrintDialogOpen] = useState(false);
  const [printLabelPayload, SetPrintLabelPayload] = useState({
    orderId: '',
    serviceItemId: '',
    itemCondition: '',
    labelCount: 0,
    itemCount: 0,
    packedBy: '',
  });
  const [selectedRow, setSelectedRow] = useState<any>({});
  const itemConditionList = ['CLEAN', 'STAIN', 'DAMAGE'];
  const queryParams = {
    page: 0,
    size: 10000,
    sortColumn: 'modifiedAt',
    isDescending: true,
  };

  // GET with API : BUSINESSSTORE
  const fetchBusinessStore = async () => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESS_STORE_ADMIN_BUSINESS_DATA_GET}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessStoreArray = response?.data?.sort((a: any, b: any) =>
          a?.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );
        setBusinessStoreData(sortBusinessStoreArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : BUSINESSSTORE
  const fetchDepartmentStore = async (busStoreId: string) => {
    httpGet(
      `${WASHUB_CONSTANTS.API_URLS.BUSINESSSTORE_DEPARTMENT_DATA_GET}/${busStoreId}`,
      queryParams,
    ).then((response) => {
      if (response.status === 200) {
        const sortBusinessDeptArray = response?.data?.sort((a: any, b: any) =>
          a?.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );
        setDepartmentList(sortBusinessDeptArray);
      } else {
        if (response.data.error) {
          console.error(
            'Error fetching BusinessStore: ',
            response.data.message,
          );
        }
      }
    });
  };

  // GET with API : Service Item
  const fetchServiceItem = async () => {
    const reqBody = {
      businessStoreId: +businessStoreId,
      businessDeptId: +departmentId,
    };
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.PACKAGING_SERVICE_ITEM_POST}`,
      reqBody,
    ).then((response) => {
      if (response.status === 200) {
        const sortServiceItemArray = response?.data?.sort((a: any, b: any) =>
          a?.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        );
        setServiceItemList(sortServiceItemArray);
      } else {
        if (response.data.error) {
          console.error('Error fetching Service Item: ', response.data.message);
        }
      }
    });
  };

  // GET with API
  const fetchPackagingLabel = async () => {
    const reqBody = {
      businessStoreId: +businessStoreId,
      businessDeptId: +departmentId,
      itemId: serviceItemId,
    };
    setIsLoading(true);
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.PACKAGING_ORDERDETAIL_POST}`,
      reqBody,
    ).then((response) => {
      if (response.status === 200) {
        setTableData((): any => response?.data);
        setIsLoading(false);
      } else {
        if (response.data.error) {
          console.error('Error fetching serviceitem: ', response.data.message);
          setTableData([]);
          setIsLoading(false);
        }
      }
    });
  };

  const columns = useMemo<MRT_ColumnDef<PackagingLabelOrderDetails>[]>(
    () => [
      {
        accessorKey: 'orderId',
        header: 'Order Id',
        size: 10,
      },
      {
        accessorKey: 'itemName',
        header: 'Item Name',
        size: 10,
      },
      {
        accessorKey: 'itemStatus',
        header: 'Item Status',
        size: 50,
      },
      {
        accessorKey: 'orderDate',
        header: 'Order Date',
        size: 10,
      },
      {
        accessorKey: 'itemCount',
        header: 'Item Count',
        size: 10,
      },
      {
        accessorKey: 'balanceItemCount',
        header: 'Balance Count',
        size: 10,
      },
    ],
    [],
  );

  const handleOpenPrintDialog = (row: any) => {
    setIsPrintDialogOpen(true);
    const reqBody = {
      orderId: row.orderId,
      serviceItemId: serviceItemId,
      itemCondition: '',
      labelCount: 0,
      itemCount: 0,
      packedBy: '',
    };
    SetPrintLabelPayload(reqBody);
    setSelectedRow(row);
  };

  const handlePrintLabel = () => {
    httpPost(
      `${WASHUB_CONSTANTS.API_URLS.PACKAGING_PRINTPACKAGE_POST}`,
      printLabelPayload,
    ).then(async (response) => {
      if (response.status === 200) {
        try {
          BrowserPrint.getLocalDevices(function(deviceList: any) {
            const device = deviceList?.printer;
            if(device?.length == 0 || device === undefined) {
              handleAlertBar('error','Printer not found');
              return;
            }
            let zebraPrinter: any = null;
            for(let i = 0; i < device?.length; i++) {
              if(device[i]?.manufacturer.toLowerCase().includes('zebra') || device[i]?.name.toLowerCase().includes('zpl')) {
                zebraPrinter = device[i];
                break;
              }
            }
            if(zebraPrinter === null) {
              handleAlertBar('error','No Zebra printer found');
              return;
            }
            response?.data?.label?.map((zpl: string) => {
              zebraPrinter.send(
                zpl,
                (response: any) => {
                  handleAlertBar('success','Labels print successfully');
                },
                (error: any) => {
                  console.error('Error in printing: ', error);
                },
              );
            });
          })
          closePrintDialog();
        } catch (error: any) {
          throw new Error('Error fetching Print label: ', error);
        }
      } else {
        if (response.data.error) {
          console.error('Error fetching Print label: ', response.data.message);
        }
      }
    });
  };

  const closePrintDialog = () => {
    setIsPrintDialogOpen(false);
  };

  const handlePrintLabelDialog = (e: any) => {
    const { name, value } = e.target;
    const newFormData = {
      ...printLabelPayload,
      [name]: (name === 'itemCondition' || name === 'packedBy') ? value : +value,
    };
    SetPrintLabelPayload(newFormData);
  };

  useEffect(() => {
    fetchBusinessStore();
  }, []);

  useEffect(() => {
    if (businessStoreId) {
      fetchDepartmentStore(businessStoreId);
    }
  }, [businessStoreId]);

  useEffect(() => {
    if (departmentId) {
      fetchServiceItem();
    }
  }, [departmentId]);

  useEffect(() => {
    if (serviceItemId) {
      fetchPackagingLabel();
    }
  }, [serviceItemId]);

  return (
    <div style={{ width: '100%' }}>
      <Grid container justifyContent={'space-between'}>
        <Grid item className="mb-1 mt-1 ms-3">
          <Typography
            color={'primary'}
            sx={{ textAlign: 'center' }}
            variant="h5"
            className="mt-0"
          >
            Packaging Label
          </Typography>
        </Grid>
      </Grid>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          padding: '2px 15px',
          minWidth: { xs: '300px', sm: '360px', md: '400px' },
          gap: '1rem',
        }}
      >
        <TextField
          size="small"
          style={{ width: '30%' }}
          key="businessStoreId"
          label="Select Business Store"
          name="businessStoreId"
          select
          onChange={(e) => {
            setBusinessStoreId(e.target.value);
          }}
          value={businessStoreId}
        >
          {businessStoreData.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size="small"
          style={{ width: '30%' }}
          key="departmentId"
          label="Select Business Department"
          name="departmentId"
          select
          onChange={(e) => {
            setDepartmentId(e.target.value);
          }}
          value={departmentId}
        >
          {departmentList.map((option: any) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {departmentId && (
          <TextField
            size="small"
            style={{ width: '30%' }}
            key="serviceItem"
            label="Select Service Item"
            name="serviceItem"
            select
            onChange={(e) => {
              setServiceItemId(e.target.value);
            }}
            value={serviceItemId}
          >
            {serviceItemList.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Stack>
      <div style={{ padding: '5px 15px' }}>
        <MaterialReactTable
          defaultColumn={{
            maxSize: 150,
            minSize: 10,
            size: 50,
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 100,
            },
          }}
          muiTablePaperProps={{
            sx: {
              width: '100%',
              '& .css-1tbggly': {
                minHeight: '2px',
              },
            },
          }}
          columns={columns}
          enableFullScreenToggle={false}
          enableStickyHeader
          positionActionsColumn="last"
          enableRowActions
          enableColumnActions={false}
          enableColumnFilters={false}
          enableFilters={true}
          enableSorting={false}
          enableHiding={false}
          enableDensityToggle={false}
          enableToolbarInternalActions={false}
          data={tableData}
          state={{ isLoading: isLoading }}
          editDisplayMode="modal"
          muiTableContainerProps={{
            sx: {
              height: '63vh',
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Button
                className="ps-1 pe-1"
                variant="contained"
                sx={{ color: 'white' }}
                onClick={() => handleOpenPrintDialog(row.original)}
              >
                <Typography variant="labelNormal">Print Label</Typography>
              </Button>
            </Box>
          )}
        />
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isPrintDialogOpen}
        onClose={closePrintDialog}
      >
        <DialogTitle textAlign="center">Print label Details</DialogTitle>
        <DialogContent>
          <Typography className="mx-2 mt-2">
            Balance count: {selectedRow?.balanceItemCount}
          </Typography>
          <div className="d-flex item-center justify-content-between">
            <TextField
              className="mx-2 mt-2"
              key="itemCount"
              required
              label="Item Count"
              name="itemCount"
              type="text"
              size="small"
              sx={{ width: '30%' }}
              value={printLabelPayload.itemCount}
              onChange={(e) => handlePrintLabelDialog(e)}
            />
            <TextField
              className="mx-2 mt-2"
              key="labelCount"
              required
              label="Label count"
              name="labelCount"
              type="text"
              size="small"
              sx={{ width: '30%' }}
              value={printLabelPayload.labelCount}
              onChange={(e) => handlePrintLabelDialog(e)}
            />
            <TextField
              className="mx-2 mt-2"
              key="itemCondition"
              required
              label="Select Item Condition"
              name="itemCondition"
              select
              size="small"
              sx={{ width: '40%' }}
              value={printLabelPayload.itemCondition}
              onChange={(e) => handlePrintLabelDialog(e)}
            >
              {itemConditionList?.map((option: string) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="d-flex item-center justify-content-between mt-2 ">
          <TextField
              fullWidth
              className='mx-2'
              id="outlined-helperText"
              key="packedBy"
              required
              label="Packed by:"
              name="packedBy"
              type="text"
              size="small"
              multiline 
              rows={2}
              value={printLabelPayload.packedBy}
              onChange={(e) => handlePrintLabelDialog(e)}
            />
          </div>
        </DialogContent>
        <DialogActions className="dialog-btn">
          <Button
            color="error"
            variant="contained"
            sx={{ color: 'white' }}
            onClick={closePrintDialog}
          >
            Cancel
          </Button>
          <Button
            onClick={handlePrintLabel}
            sx={{ color: 'white' }}
            variant="contained"
            disabled={
              (printLabelPayload.labelCount <= 0 ||
              printLabelPayload.itemCondition === '' ||
              printLabelPayload.itemCount <= 0 ||
              printLabelPayload.packedBy === '')
            }
          >
            Print Label
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
